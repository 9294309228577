import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DecisionMakingType } from '@dltru/dfa-models'
import { Button, EditSquare } from '@dltru/dfa-ui'

import { postDfa } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { SendForApproveModal } from '@components/Modals/Issue'


export const SendForApproveAction: FC = () => {
    const dfa = useSelector(dfaDetailsSelector.selectDfaDetails)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const reduxDispatch = useDispatch()

    if (dfa.decision_making_type !== DecisionMakingType.platform) {
        return null
    }

    const handleOk = () => {
        reduxDispatch(postDfa(dfa))
        setIsModalVisible(false)
    }

    return (
        <>
            <Button
                onClick={() => setIsModalVisible(true)}
                icon={<EditSquare />}
                borderRadius={16}
                type="primary"
            >
                Подать заявление
            </Button>
            {isModalVisible && (
                <SendForApproveModal
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    handleOk={handleOk}
                />
            )}
        </>
    )
}