import { CONTENT_FOOTER_ID, ContentFooterBox, IFooter, Portal } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { authSelector } from '@store/auth/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { SendForCheckAction } from '@pages/DFAIssueDecisionPage/components/Footer/actions/SendForCheckAction'

import { SendForApproveAction } from './actions/SendForApproveAction'

export const Footer: FC<IFooter> = ({ status }) => {
    const navigate = useNavigate()
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const dfa = useSelector(dfaDetailsSelector.selectDfaDetails)
    const userUID = useSelector(authSelector.selectUserUid)

    useEffect(() => {
        if (!dfa.isLoading && dfa.id) {
            navigate(`/clients/${userUID}`, { state: { tabKey: 'dfas' } })
        }
    }, [dfa.isLoading, dfa.id])

    if (status !== 'precreate') {
        return null
    }
    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    <ContentFooterBox>
                        <SendForApproveAction />
                        <SendForCheckAction />
                    </ContentFooterBox>
                </Portal>
            )}
        </>
    )
}
