import { DecisionMakingType } from '@dltru/dfa-models'
import { Button, EditSquare } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { SendForPreCheckModal } from '../modals/SendForPreCheckModal'

export const SendForCheckAction: FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const dfa = useSelector(dfaDetailsSelector.selectDfaDetails)
    if (dfa.decision_making_type !== DecisionMakingType.out_of_platform) {
        return null
    }

    return (
        <>
            <Button
                onClick={() => setIsModalVisible(true)}
                icon={<EditSquare />}
                borderRadius={16}
                type="primary"
            >
                Направить на проверку
            </Button>
            <SendForPreCheckModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
        </>
    )
}
