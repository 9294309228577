import { DecisionMakingTypeTranslate } from '@dltru/dfa-models'
import { Alert } from '@dltru/dfa-ui'
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Form,
    FormItem,
    Radio,
    Space,
} from '@dltru/dfa-ui'
import { Dispatch, FC, SetStateAction } from 'react'

import { IDFAIssueDecisionProps } from './DFAIssueDecisionPage'
import { infoTextPart1, infoTextPart2 } from './consts'

interface IDFAIssueDecisionPresetFormProps extends IDFAIssueDecisionProps {
    setPresetFormValue: Dispatch<SetStateAction<IDFAIssueDecisionProps['presetForm']>>
}
export const DFAIssueDecisionPresetForm: FC<IDFAIssueDecisionPresetFormProps> = ({
    presetForm,
    setPresetFormValue,
    setFormStatus,
}) => {
    const [form] = Form.useForm()

    const onValuesChange = (changedValues: Partial<typeof presetForm>) => {
        setPresetFormValue((prevValues) => ({ ...prevValues, ...changedValues }))
    }

    return (
        <Form form={form} initialValues={presetForm} onValuesChange={onValuesChange}>
            <Card>
                <CardHeader title="Общая информация" />
                <Divider margin={[0, 0, 40, 0]} />
                <CardContent>
                    <div className="formRow">
                        <FormItem
                            name="decision_making_type"
                            label="Шаблон Решения о выпуске"
                            required
                        >
                            <Radio.Group>
                                <Space direction="vertical">
                                    {Object.entries(DecisionMakingTypeTranslate).map(
                                        ([key, value]) => (
                                            <Radio key={key} value={key}>
                                                {value}
                                            </Radio>
                                        ),
                                    )}
                                </Space>
                            </Radio.Group>
                        </FormItem>
                    </div>
                    <Box width="600px">
                        <Alert
                            showIcon
                            type="info"
                            description={
                                <>
                                    <p>{infoTextPart1}</p>
                                    <p>{infoTextPart2}</p>
                                </>
                            }
                        />
                    </Box>
                </CardContent>
                <Divider />
                <Box padding={[0, 8, 8, 8]} direction="row" justify="left">
                    <Button onClick={() => setFormStatus('main')} type="primary" borderRadius={12}>
                        Далее
                    </Button>
                </Box>
            </Card>
        </Form>
    )
}
