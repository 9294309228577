import { DecisionMakingType, IDfa } from '@dltru/dfa-models'
import { Box, PageHeader } from '@dltru/dfa-ui'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { dropDfaDetails } from '@store/dfa/details'

import { DFAIssueDecisionMainForm } from './DFAIssueDecisionMainForm'
import { DFAIssueDecisionPresetForm } from './DFAIssueDecisionPresetForm'

export interface IDFAIssueDecisionProps {
    presetForm: Pick<IDfa, 'decision_making_type'>
    setFormStatus: (status: FormStatusType) => void
}
export type FormStatusType = 'presets' | 'main'
export default function DFAIssueDecisionPage() {
    const reduxDispatch = useDispatch()
    const [formStatus, setFormStatus] = useState<FormStatusType>('presets')
    const [presetFormValue, setPresetFormValue] = useState<IDFAIssueDecisionProps['presetForm']>({
        decision_making_type: DecisionMakingType.platform,
    })

    useEffect(() => {
        reduxDispatch(dropDfaDetails())
    })

    const backFromFirstStep = () => {
        setFormStatus('presets')
    }

    return (
        <>
            <Box padding={16}>
                <PageHeader title="Новый выпуск ЦФА" />
            </Box>
            {formStatus === 'presets' && (
                <DFAIssueDecisionPresetForm
                    setFormStatus={setFormStatus}
                    setPresetFormValue={setPresetFormValue}
                    presetForm={presetFormValue}
                />
            )}
            {formStatus === 'main' && (
                <DFAIssueDecisionMainForm
                    presetForm={presetFormValue}
                    backFromFirstStep={backFromFirstStep}
                />
            )}
        </>
    )
}
