import { Box, Button, MiniModal, QuestionCircle, Space } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { postDfa } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}

export const SendForPreCheckModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
}) => {
    const [isClicked, setIsClicked] = useState<boolean>(false)
    const handleCancel = () => {
        setIsModalVisible(false)
    }
    const dfa = useSelector(dfaDetailsSelector.selectDfaDetails)

    const reduxDispatch = useDispatch()
    const handleOk = () => {
        setIsClicked(true)
        setTimeout(() => {
          reduxDispatch(postDfa(dfa))
        }, 500)
        
    }

    return (
        <MiniModal
            visible={isModalVisible}
            onCancel={handleCancel}
            width={600}
            title={
                <Space size={0}>
                    <QuestionCircle className="modal-icon modal-question-icon" />
                    Направить на предварительную проверку
                </Space>
            }
            footer={[
                <Button key="cancel" borderRadius={12} onClick={handleCancel}>
                    Отмена
                </Button>,
                <Button key="ok" borderRadius={12} type="primary" onClick={handleOk} disabled={isClicked} loading={isClicked}>
                    Да, направить на проверку
                </Button>,
            ]}
        >
            <Box padding={[0, 0, 8, 40]}>
                <p className="modal-content-text">
                    В ходе предварительной проверки сотрудник Оператора ИС заполнит карточку выпуска
                    ЦФА, после чего вы сможете подтвердить согласие с комиссией и направить
                    заявление о допуске ЦФА к выпуску на рассмотрение Оператору ИС.
                </p>
                <div>Вы действительно хотите направить заявление на предварительную проверку?</div>
            </Box>
        </MiniModal>
    )
}
