import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    DecisionMakingType,
    DecisionMakingTypeTranslate,
    IDfa,
    getDocumentUid,
} from '@dltru/dfa-models'
import { AccessibilityStep, Alert, Box, IFooter, InfoCardsDfa, Row, StepperC } from '@dltru/dfa-ui'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { paymentSchedulerSelector } from '@store/paymentsScheduler/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

import api from '@services/api'

import { InvestorsBlock } from '@components/DFADetails/components/InvestorsBlock/InvestorsBlock'

import { updateDfaDetails } from '../../store/dfa/details'

import { Footer } from './components/Footer'
import { ApplicationStep } from './components/steps/ApplicationStep'
import { AttachmentsStep } from './components/steps/AttachmentsStep'
import { ConditionStep } from './components/steps/ConditionStep'
import { CouponPaymentsStep } from './components/steps/CouponPaymentsStep/CouponPaymentsStep'
import { DecisionStep } from './components/steps/DecisionStep'

const stepIndexGenerator =
    (initialIndex = 0) =>
    () =>
        initialIndex++

interface IComponentProps {
    presetForm: Pick<IDfa, 'decision_making_type'>
    backFromFirstStep?: () => void
}
export const DFAIssueDecisionMainForm: FC<IComponentProps> = ({
    presetForm,
    backFromFirstStep,
}) => {
    const reduxDispatch = useDispatch()
    const [footerStatus, setFooterStatus] = useState<IFooter['status']>('initial')
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const profileData = useSelector(profileSelector.emitterData)
    const userInn = useSelector(profileSelector.userInn) as unknown as
        | string
        | number
        | undefined
        | null
    const couponPaymentCalendar = useSelector(paymentSchedulerSelector.getDates)
    const getStepIndex = stepIndexGenerator()

    const onStepperFinish = (value: any) => {
        value.issue_end_date = value.issue_end_date?.valueOf()
        value.issue_start_date = value.issue_start_date?.valueOf()
        value.redeem_date = value.redeem_date?.valueOf()
        value.document = getDocumentUid(value.document)
        value.document_signature = getDocumentUid(value.document_signature)
        value.attachments = getDocumentUid(value.attachments)
        value.admission_application = getDocumentUid(value.admission_application)
        value.admission_application_signature = getDocumentUid(
            value.admission_application_signature,
        )
        if (value.coupon_payment_amount) {
            value.coupon_payment_amount = parseFloat(
                value.coupon_payment_amount as unknown as string,
            )
        }
        value.coupon_payment_calendar = couponPaymentCalendar
        reduxDispatch(updateDfaDetails({ ...value, ...presetForm }))
        setFooterStatus('precreate')
    }
    const makingTypeOutOfPlatform =
        presetForm?.decision_making_type === DecisionMakingType.out_of_platform

    return (
        <>
            <Box padding={24} margin={[0, 0, 8, 0]} className="tablebox tablebox--auto-height">
                <Row gutter={16}>
                    <InfoCardsDfa data={profileData} />
                </Row>
            </Box>
            {presetForm?.decision_making_type && (
                <Box margin={[0, 0, 8, 0]}>
                    <Alert
                        showIcon
                        type="info"
                        description={DecisionMakingTypeTranslate[presetForm.decision_making_type]}
                    />
                </Box>
            )}

            <StepperC
                initialStep={0}
                initialValues={{ ...dfaDetails }}
                onStepperFinish={onStepperFinish}
            >
                <ConditionStep
                    stepIndex={getStepIndex()}
                    decisionMakingType={presetForm?.decision_making_type}
                    backFromFirstStep={backFromFirstStep}
                />
                {presetForm?.decision_making_type === DecisionMakingType.platform && (
                    <CouponPaymentsStep
                        backFromFirstStep={backFromFirstStep}
                        stepIndex={getStepIndex()}
                    />
                )}

                <AccessibilityStep
                    stepIndex={getStepIndex()}
                    isModification
                    noAvailableInn={userInn}
                    CustomInvestorsBlock={InvestorsBlock}
                    getUids={api.lib.getUidsByInnType}
                />
                <ApplicationStep stepIndex={getStepIndex()} />
                <DecisionStep
                    stepIndex={getStepIndex()}
                    makingTypeOutOfPlatform={makingTypeOutOfPlatform}
                />
                <AttachmentsStep stepIndex={getStepIndex()} />
            </StepperC>
            <Footer status={footerStatus} setFooterStatus={setFooterStatus} />
        </>
    )
}
